import { render, staticRenderFns } from "./fullscreen.vue?vue&type=template&id=dd95b89a&"
import script from "./fullscreen.vue?vue&type=script&lang=js&"
export * from "./fullscreen.vue?vue&type=script&lang=js&"
import style0 from "./fullscreen.vue?vue&type=style&index=0&id=dd95b89a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {RzIconCross: require('/opt/yamal.ru/node_modules/razlet-ui/src/icons/icon-cross/index.vue').default})
