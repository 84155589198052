
import {lockScroll, unlockScroll} from '~/utils/lock-scroll';

export default {
  name: 'base-fullscreen',
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    dark: {
      type: Boolean,
      default: false,
    },
    showCloseText: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    isOpen: {
      handler(value) {
        if (value) lockScroll();
        else {
          setTimeout(() => {
            unlockScroll();
          }, 600);
        }
      },
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
